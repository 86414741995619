import * as React from 'react'
import { graphql } from 'gatsby'

import Layout from "../components/Layout"
import defaults from "../components/dafaults"
import Search from "../components/Search/index"

const Page = ({data}) => {

  console.log("🚀 ~ file: test.js:5 ~ Page ~ data", data)

    return (
      <div className="content">
        {/* <h1 dangerouslySetInnerHTML={{__html: data.title.rendered }}></h1> */}
        <Search></Search>
        <div className="insideContent">
          {/* <div dangerouslySetInnerHTML={{__html: data.content.rendered }} /> */}
        </div>
        {/* <Form type="Contato" structure={defaultsData.Contato} /> */}
      </div>
  )
}

export const query = graphql`
  query HomePageQuery {
    site {
      siteMetadata {
        description
      }
    }
  }
`

export default Page